import Vue from "vue";
import Vuex from "vuex";
import Api from "@/modules/axios";
import config from "@/config.ts";
import Panier from "@/store/panier";
import Organisme from "@/store/organisme";
import Dotation from "@/store/dotation";
import Sentry from "@/modules/sentry";
import Moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Panier,
    Organisme,
    Dotation,
  },
  state: {
    user: null,
    permissions: null,
    devisCount: null,
    devisSoumisApprouvesCount: null,
    accessForbidden: false,
    askLinkAccount: false,
    errorStatus: null,
    isLogged: false,
    preferences: JSON.parse(localStorage.getItem("preferences")) || {},
    suggestionFilter: null,
    isSuggestionFilterOpen: true,
    country: null,
    askChooseOrg: false,
    componentSearchKey: 0,
    advancedSearchActive: false,
    selectedLines: [], // Offres à rajouter dans la liste (lors de la modale de sélection/création)
    activeLists: [],
    isFetchingActiveLists: false,
    panierToListResolver: null,
    identifyFailed: false,
  },
  getters: {
    isLogged(state) {
      return state.isLogged;
    },
    user(state) {
      return state.user;
    },
    devisCount(state) {
      return state.devisCount;
    },
    devisSoumisApprouvesCount(state) {
      return state.devisSoumisApprouvesCount;
    },
    accessForbidden(state) {
      return state.accessForbidden;
    },
    askLinkAccount(state) {
      return state.askLinkAccount;
    },
    errorStatus(state) {
      return state.errorStatus;
    },
    country(state) {
      return state.country;
    },
    preferences(state) {
      return state.preferences;
    },
    suggestionFilter(state) {
      return state.suggestionFilter;
    },
    isSuggestionFilterOpen(state) {
      return state.isSuggestionFilterOpen;
    },
    hasPerm: (state) => (perm) => state.permissions.includes(perm),
    hasPerms: (state, getters) => (perms) => {
      /*
        Possède la permission view_devis:
        permissions: [["view_devis"]]
        OU
        permissions: ["view_devis"]

        Possède la permission view_devis ET view_liste
        permissions: [["view_devis", "view_liste"]]
        OU
        permissions: ["view_devis", "view_liste"]

        Possède la permission view_devis OU view_liste
        permissions: [["view_devis"], ["view_liste"]]

        Possède la permission view_devis OU (view_liste ET view_facture)
        permissions: [["view_devis"], ["view_liste", "view_facture"]]
      */
      if (!perms) {
        return true;
      }

      if (typeof perms[0] === "string" || perms[0] instanceof String) {
        // Tableau de strings = possède toutes les perms
        return perms.every((p) => ((p[0] === "!" ? !getters.hasPerm(p.slice(1)) : getters.hasPerm(p))));
      }
      // Tableau de tableaux = possède au moins toutes les perms d'un des tableaux enfants
      return perms.some((arrPerm) => arrPerm.every(
        (p) => (p[0] === "!" ? !getters.hasPerm(p.slice(1)) : getters.hasPerm(p)),
      ));
    },
    impersonator(state) {
      return state.user ? state.user.impersonator : null;
    },
    impersonating(state) {
      return !!state.user.impersonator?.username;
    },
    askChooseOrg(state) {
      return state.askChooseOrg;
    },
    urlHotline(state) {
      let url = `${config.url.hotlineTicket}?categorie=33`; // https://hotline.lde.fr/scenarisation/viewer/

      const id = state.Organisme?.organismeActuel?.id_organisme;

      if (id) {
        url += `&id_client=${id}`;
      }

      return url;
    },
    urlCristalWeb(state, getters) {
      const urlBase = config.url.cristalWeb;

      if (getters.isGMBH) {
        return urlBase;
      }

      const numServer = state.Organisme?.organismeActuel?.infos?.num_serveur_cw;
      const dotIndex = urlBase.indexOf(".");

      // On ajoute le numéro du serveur dans l'URL
      return urlBase.slice(0, dotIndex) + numServer + urlBase.slice(dotIndex);
    },
    searchKey(state) {
      return state.componentSearchKey;
    },
    advancedSearchActive(state) {
      return state.advancedSearchActive;
    },
    selectedLines(state) {
      return state.selectedLines;
    },
    isGMBH() {
      // return true;
      return window.location.host.endsWith(".lde.de");
    },
    activeLists(state) {
      return state.activeLists;
    },
    isFetchingActiveLists(state) {
      return state.isFetchingActiveLists;
    },
    panierToListResolver(state) {
      return state.panierToListResolver;
    },
    identifyFailed(state) {
      return state.identifyFailed;
    },
  },
  mutations: {
    setLogged(state) {
      state.isLogged = true;
    },
    setUser(state, user) {
      state.user = user;
      if (user.avatar) {
        state.user.avatar = user.avatar;
      }
    },
    setAvatar(state, { avatar, avatar_from_library: lib }) {
      state.user.avatar = avatar;
      state.user.avatar_from_library = lib;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setDevisCount(state, devisCount) {
      state.devisCount = devisCount;
    },
    setDevisSoumisApprouvesCount(state, devisSoumisApprouvesCount) {
      state.devisSoumisApprouvesCount = devisSoumisApprouvesCount;
    },
    setAccessForbidden(state, accessForbidden) {
      state.accessForbidden = accessForbidden;
    },
    setAskLinkAccount(state, askLinkAccount) {
      state.askLinkAccount = askLinkAccount;
    },
    setErrorStatus(state, errorStatus) {
      state.errorStatus = errorStatus;
    },
    setCookies(state, cookies) {
      state.user.cookies = cookies;
    },
    setIsSuggestionFilterOpen(state, isSuggestionFilterOpen) {
      // Si elle n'existe pas, on met la valeur true, sinon on met la valeur récupérer de String en Boolean
      const isOpen = isSuggestionFilterOpen === null || JSON.parse(isSuggestionFilterOpen);
      state.isSuggestionFilterOpen = isOpen;
      localStorage.setItem("isSuggestionFilterOpen", String(isOpen));
    },
    setSearchSuggestionFilter(state, suggestionFilter) {
      state.suggestionFilter = suggestionFilter || null;
      if (suggestionFilter) {
        localStorage.setItem("suggestionFilter", suggestionFilter);
      }
    },
    updatePreferences(state, preferences) {
      state.preferences = { ...state.preferences, ...preferences };
      localStorage.setItem("preferences", JSON.stringify(state.preferences));
    },
    setCountry(state, country) {
      state.country = country || "fr";
    },
    setAskChooseOrg(state, askChooseOrg) {
      state.askChooseOrg = askChooseOrg;
    },
    addSearchKey(state) {
      state.componentSearchKey += 1;
    },
    toggleAdvSearch(state) {
      state.advancedSearchActive = !state.advancedSearchActive;
    },
    setSelectedLines(state, lines) {
      state.selectedLines = lines;
    },
    setActiveLists(state, activeLists) {
      state.activeLists = activeLists;
    },
    setIsFetchingActiveLists(state, bool) {
      state.isFetchingActiveLists = bool;
    },
    setPanierToListResolver(state, resolver) {
      state.panierToListResolver = resolver;
    },
    setIdentifyFailed(state, bool) {
      state.identifyFailed = bool;
    },
  },
  actions: {
    login(store, body) {
      Sentry.init();
      Sentry.setScope();
      store.commit("setCountry", store.getters.isGMBH ? "de" : "fr");

      return Api({ noHeader: true })
        .post("/identify/", body)
        .then(async ({ data }) => {
          store.commit("setUser", data.user);
          window._paq.push(["setUserId", data.user.id]);

          if (store.state.user?.cookies?.audience) {
            window._paq.push(["setConsentGiven"]);
          }

          const today = Moment();
          const dateCookie = Moment(store.state.user?.cookies?.date, "DD/MM/YYYY - HH:mm:ss");
          // Si le cookie est supérieur à 6 mois
          if (dateCookie.diff(today, "months", true) > 6) {
            store.commit("setCookies", {});
          }

          store.commit("setOrganismeActuel", data.organisme_actuel);
          store.commit("setPermissions", data.permissions);
          store.commit("setDevisCount", data.devis_count);
          store.commit("setDevisSoumisApprouvesCount", data.devis_soumis_approuves_count);
          store.commit("setAskLinkAccount", data.ask_link_account);
          store.commit("setAskChooseOrg", data.ask_choose_org);
          store.commit("setAccessForbidden", data.forbidden);

          if (localStorage) {
            store.commit("setSearchSuggestionFilter", localStorage.getItem("suggestionFilter"));
            store.commit("setIsSuggestionFilterOpen", localStorage.getItem("isSuggestionFilterOpen"));
          }

          if (data.organisme_actuel) {
            store.dispatch("setPanier");
            store.dispatch("fetchActiveLists");
          }

          store.commit("setLogged");
          return data;
        })
        .catch((err) => {
          if (body && body.noRedirect) {
            return false;
          }
          if (err.response && err.response.status === 401) {
            window.location = `${config.loginUrl}?next=${window.location.href}`;
          } else if (err.response
            && err.response.status === 403
            && err.response.data.detail.indexOf("CSRF") > -1
          ) {
            document.cookie.split(";").forEach((c) => {
              document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
            });
            window.location = config.logoutUrl;
          } else {
            const infos = {
              name: "IDENTIFY_FAILED",
              date: new Date(),
              user: store.state?.user?.username,
              error: err.message,
              store: store.state,
              localStorage: localStorage.valueOf(),
              cookies: document.cookie,
            };

            class IdentifyFailed extends Error {
              constructor() {
                super("Identify failed");
                this.name = "Identify failed";
              }
            }

            Sentry.client.captureException(new IdentifyFailed(), {
              extra: infos,
            });

            store.commit("setIdentifyFailed", true);
          }

          return true;
        });
    },
    toggleAdvSearch(store, toggle) {
      store.commit("toggleAdvSearch", toggle);
    },
    /**
     * Récupère les listes actives de l'utilisateur pour le site en général en dehors des vues "liste".
     * @param {Object} commit
     * @param {Object} getters
     * @returns {Promise} Listes actives.
     */
    fetchActiveLists({ commit, getters }) {
      commit("setIsFetchingActiveLists", true);
      const params = {
        utilisateur: getters.user.id,
        date_transfert__isnull: true,
        panier: false,
      };

      return Api().get("/liste/", { params })
        .then(({ data }) => {
          commit("setActiveLists", data.results);
          commit("setIsFetchingActiveLists", false);
          return data;
        });
    },
  },
});
