import {
  UilBookOpen,
  UilBookmark,
  UilDashboard,
  UilFileCheck,
  UilAward,
  UilTruck,
  UilPricetagAlt,
} from "@iconscout/vue-unicons";

import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconPapier from "@/components/icons/IconPapier.vue";
import IconPapierAlt from "@/components/icons/IconPapierAlt.vue";
import IconNumerique from "@/components/icons/IconNumerique.vue";
import IconFourniture from "@/components/icons/IconFourniture.vue";
import IconEstablishment from "@/components/icons/IconEstablishment.vue";

import i18n from "@/modules/i18n";
import { getUrls } from "@/router";
import store from "@/store";

/* Voir /store/index.js:hasPerms pour les explications de permissions */
export default [
  {
    ...getUrls("url.catalogues"),
    name: "catalogues",
    title: i18n.t("menu.catalogues-lde"),
    icon: UilBookOpen,
    beforeEnter: (to, from, next) => {
      if (store.getters.hasPerms(["view_articlepapier"])) {
        next({ name: "catalogues_livres_manuels_papier" });
      } else if (store.getters.hasPerms(["view_manuelnumerique"])) {
        next({ name: "catalogues_ressources_numeriques" });
      } else if (store.getters.hasPerms(["view_fourniture"])) {
        next({ name: "catalogues_fournitures_papeterie" });
      } else {
        next({ name: "403" });
      }
    },
    submenu: [
      {
        ...getUrls("url.livres_manuels_papier"),
        name: "livres_manuels_papier",
        title: i18n.t("menu.livres-et-manuels-papier"),
        permissions: ["view_articlepapier"],
        icon: IconPapier,
        component: () => import(
          /* webpackChunkName: "catalogues_livres_manuels_papier" */ "../views/LivresManuelsPapier.vue"
        ),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            goBackBtn: true,
            permissions: ["view_articlepapier"],
            component: () => import(
              /* webpackChunkName: "catalogues_livres_manuels_papier_item" */ "../views/FicheLivrePapier.vue"
            ),
          },
        ],
      },
      {
        ...getUrls("url.ressources_numeriques"),
        name: "ressources_numeriques",
        title: i18n.t("menu.ressources-numeriques"),
        icon: IconNumerique,
        permissions: ["view_manuelnumerique"],
        component: () => import(
          /* webpackChunkName: "catalogues_ressources_numeriques" */ "../views/RessourcesNumeriques.vue"
        ),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            permissions: ["view_manuelnumerique"],
            goBackBtn: true,
            component: () => import(
              /* webpackChunkName: "catalogues_ressources_numeriques_item" */ "../views/FicheManuelNumerique.vue"
            ),
          },
        ],
      },
      {
        ...getUrls("url.fournitures_papeterie"),
        name: "fournitures_papeterie",
        title: i18n.t("menu.fournitures-et-papeterie"),
        permissions: ["view_fourniture"],
        component: () => import(
          /* webpackChunkName: "catalogues_fournitures_papeterie" */ "../views/FournituresPapeterie.vue"
        ),
        icon: IconFourniture,
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            permissions: ["view_fourniture"],
            goBackBtn: true,
            component: () => import(
              /* webpackChunkName: "catalogues_fournitures_papeterie_item" */ "../views/FicheFourniture.vue"
            ),
          },
        ],
      },
    ],
  },
  {
    ...getUrls("url.listes_devis"),
    name: "listes_devis",
    title: i18n.t("menu.listes"),
    icon: UilBookmark,
    redirect: { name: "listes_devis_listes" },
    permissions: [["view_liste"], ["view_devis"]],
    submenu: [
      {
        ...getUrls("url.listes"),
        name: "listes",
        title: i18n.t("menu.vos-listes"),
        icon: UilBookmark,
        permissions: ["view_liste"],
        component: () => import(/* webpackChunkName: "listes_devis_listes" */ "../views/Liste.vue"),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            icon: UilBookmark,
            permissions: ["view_liste"],
            goBackBtn: true,
            component: () => import(/* webpackChunkName: "listes_devis_listes_item" */ "../views/FicheListe.vue"),
          },
        ],
      },
      {
        ...getUrls("url.devis"),
        name: "devis",
        title: i18n.t("menu.vos-devis"),
        icon: IconFileDevis,
        permissions: ["view_devis", "!can_view_as_maitre_compta"],
        component: () => import(/* webpackChunkName: "listes_devis_devis" */ "../views/Devis.vue"),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            icon: IconFileDevis,
            permissions: ["view_devis"],
            goBackBtn: true,
            component: () => import(/* webpackChunkName: "listes_devis_devis_item" */ "../views/FicheDevis.vue"),
          },
        ],
      },
      {
        ...getUrls("url.devis_soumis"),
        name: "devis_soumis",
        title: i18n.t("menu.vos-devis-soumis"),
        icon: IconFileDevis,
        permissions: ["view_devis", "can_view_as_maitre_compta"],
        component: () => import(/* webpackChunkName: "listes_devis_devis_soumis" */ "../views/Devis.vue"),
      },
      {
        ...getUrls("url.devis_non_soumis"),
        name: "devis_non_soumis",
        title: i18n.t("menu.vos-devis-non-soumis"),
        icon: IconFileDevis,
        permissions: ["view_devis", "can_view_as_maitre_compta"],
        component: () => import(/* webpackChunkName: "listes_devis_devis_non_soumis" */ "../views/Devis.vue"),
      },
    ],
  },
  {
    ...getUrls("url.commandes_factures"),
    name: "commandes_factures",
    title: i18n.t("menu.commandes"), // Ou "Commandes & factures" => Défini dans App.vue selon perm
    icon: UilFileCheck,
    redirect: { name: "commandes_factures_commandes" },
    // permissions: [["view_commande"], ["view_facture"], ["view_expedition"]],
    permissions: [["view_commande"], ["view_facture"]],
    submenu: [
      {
        ...getUrls("url.commandes"),
        name: "commandes",
        title: i18n.t("menu.commandes"),
        icon: UilFileCheck,
        permissions: ["view_commande"],
        component: () => import(/* webpackChunkName: "commandes_factures_commandes" */ "../views/Commande.vue"),
        submenu: [
          {
            ...getUrls("url.restant_a_livrer"),
            name: "ral",
            title: i18n.t("commande.restant-a-livrer"),
            icon: UilTruck,
            permissions: ["view_commande"],
            goBackBtn: true,
            component: () => import(
              /* webpackChunkName: "commandes_factures_commandes_ral" */ "../views/LDC_RestantALivrer.vue"
            ),
          },
          {
            ...getUrls(":id"),
            name: "item",
            icon: UilFileCheck,
            permissions: ["view_commande"],
            goBackBtn: true,
            component: () => import(
              /* webpackChunkName: "commandes_factures_commandes_item" */ "../views/FicheCommande.vue"
            ),
          },
        ],
      },
      {
        ...getUrls("url.factures"),
        name: "factures",
        title: i18n.t("menu.factures-avoirs"),
        icon: UilFileCheck,
        permissions: ["view_facture"],
        component: () => import(/* webpackChunkName: "commandes_factures_factures" */ "../views/Facture.vue"),
      },
      {
        ...getUrls("url.expeditions"),
        name: "expeditions",
        title: i18n.t("menu.expeditions"),
        icon: UilTruck,
        permissions: ["view_expedition"],
        component: () => import(/* webpackChunkName: "commandes_factures_expeditions" */ "../views/Expedition.vue"),
      },
    ],
  },
  {
    ...getUrls("url.licences"),
    name: "licences",
    title: i18n.t("menu.licences-numeriques"),
    icon: UilAward,
    redirect: { name: "licences_actives" },
    permissions: ["can_commander_licence_supp"],
    submenu: [
      {
        ...getUrls("url.actives"),
        name: "actives",
        title: i18n.t("menu.licences-commandees"),
        icon: IconNumerique,
        permissions: ["can_commander_licence_supp"],
        component: () => import(/* webpackChunkName: "licences_actives" */ "../views/Licences.vue"),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            icon: IconNumerique,
            permissions: ["can_commander_licence_supp"],
            goBackBtn: true,
            component: () => import(/* webpackChunkName: "licences_actives_item" */ "../views/FicheLicence.vue"),
          },
        ],
      },
    ],
  },
  {
    ...getUrls("url.stocks_physiques"),
    name: "stocks_physiques",
    title: i18n.t("menu.stocks-physiques"),
    icon: IconPapierAlt,
    redirect: { name: "stocks_physiques_etiquettes" },
    permissions: ["use_cristalweb", "view_articlepapier", "can_order_etiquette"],
    submenu: [
      {
        ...getUrls("url.etiquettes"),
        name: "etiquettes",
        title: i18n.t("menu.commande-etiquettes"),
        icon: UilPricetagAlt,
        permissions: ["use_cristalweb", "view_articlepapier", "can_order_etiquette"],
        component: () => import(/* webpackChunkName: "stocks_physiques_etiquettes" */ "../views/Etiquettes.vue"),
      },
      {
        title: "Cristal Web",
        name: "cristal_web",
        extLink: store.getters.urlCristalWeb, // Redéfini dans App.vue selon perm pour le num serveur
        permissions: ["use_cristalweb"],
      },
    ],
  },
  {
    ...getUrls("url.etablissements"),
    name: "etablissements",
    title: i18n.tc("menu.mes-etablissements", 1),
    icon: IconEstablishment,
    redirect: { name: "etablissements_actuel" },
    submenu: [
      {
        ...getUrls("url.tous"),
        name: "tous",
        title: i18n.t("menu.tableau-bord-suivi"),
        icon: UilDashboard,
        permissions: ["can_view_as_maitre_compta"],
        component: () => import(/* webpackChunkName: "etablissements_tous" */ "../views/EtablissementRegion.vue"),
        submenu: [
          {
            ...getUrls(":id"),
            name: "item",
            icon: IconEstablishment,
            permissions: ["can_view_as_maitre_compta"],
            goBackBtn: true,
            component: () => import(
              /* webpackChunkName: "etablissements_tous_item" */ "../views/FicheEtablissement.vue"
            ),
          },
        ],
      },
      {
        ...getUrls("url.actuel"),
        name: "actuel",
        title: i18n.t("menu.mon-compte"),
        icon: IconEstablishment,
        component: () => import(/* webpackChunkName: "etablissements_actuel" */ "../views/FicheEtablissement.vue"),
      },
    ],
  },
];
