import router from "@/router";
import matomoApiMethods from "@/modules/matomo_api_list.json";

// ====================================== DIRECTIVE ======================================
const matomoLogClickDirective = {
  bind: (el, binding, vnode) => {
    el.logClickToMatomo = () => {
      // [0] = catégorie, [1]=action, [2]=name(utilisé pour des infos en plus ici)
      const categorie = binding.value[0];
      const action = binding.value.length > 1 ? binding.value[1] : "click";
      const autres = binding.value.length > 2 ? binding.value[1] : null;
      vnode.context.$matomo.trackEvent(categorie, action, autres);
    };

    // Si un enfant est un bouton, bind l'event dessus, sinon problème de propagation (button-status par exemple)
    let btn = el.querySelector("button");
    if (!btn) {
      btn = el.querySelector(".button-classic");
    }

    (btn || el).addEventListener("click", el.logClickToMatomo);
  },
  unbind: (el) => {
    el.removeEventListener("click", el.logClickToMatomo);
  },
};

function handleMatomoBlocked(Vue) {
  console.log("Matomo proxy"); // ne pas supprimer
  const matomoProxy = {};

  matomoApiMethods.forEach((m) => {
    matomoProxy[m] = () => { };
  });

  Vue.prototype.$matomo = matomoProxy;
}

// ====================================== VARIABLES ======================================

let env = "devel";
if (process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT !== "devel") {
  env = process.env.VUE_APP_ENVIRONMENT === "master" ? "production" : "recette";
}

let host = "http://localhost:8089";
let siteId = 1;
if (env === "production") {
  host = "https://matomo.lde.fr";
  siteId = window.location.host.includes("test-num") ? 8 : 1;
} else if (env === "recette") {
  host = "https://matomo.lde.fr";
  siteId = 2;
}

// ====================================== EXPORTS ======================================

export { matomoLogClickDirective, handleMatomoBlocked };

export default {
  host,
  siteId,
  // Enables automatically registering pageviews on the router
  router,
  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: "matomo",

  requireConsent: true,
  // Whether or not to log debug information
  // Default: false
  debug: env !== "production" && env !== "recette",
  // Install a Heart beat timer that will send additional requests to Matomo in order to better measure the time
  // spent in the visit. These requests will be sent only when the user is actively viewing the page
  // (when the tab is active and in focus)
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 10,
};
