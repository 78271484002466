import store from "@/store/index";

import i18n from "@/modules/i18n";

// Échange une traduction concernant la "région" avec une traduction correspondante pour "département" et "mairie".
export default function typeMarcheTraduction() {
  const marcheType = store.getters.organismeActuel?.marches[0]?.type;
  let transKeys = [];
  // Choisir quelles clés de traduction utiliser selon le marché concerné
  if (marcheType === "Mairie") {
    transKeys = [
      "compte.soumission-a-region-mairie",
      "devis.devis-a-approuver-help-mairie",
      "devis.devis-a-commander-help-mairie",
      "devis.devis-a-modifier-help-mairie",
      "devis.devis-a-soumettre-help-mairie",
      "devis.devis-rejete-help-mairie",
      "devis.devis-soumis-a-region-mairie",
      "devis.devis-soumis-a-region-help-mairie",
      "devis-commande.tooltip.devis-approuve-en-attente-commande-mairie",
      "devis-commande.tooltip.devis-soumis-en-attente-approbation-mairie",
      "devis-commande.tooltip.devis-valide-attente-soumission-mairie",
      "dotation.alloue-help-mairie",
      "dotation.devis-non-soumis-help-mairie",
      "dotation.devis-transmis-help-mairie",
      "dotation.outil-permet-suivre-budget-alloue-mairie",
      "etablissement.devis-en-attente-transmission-region-mairie",
      "timeline.approbation-region-mairie",
      "timeline.approuve-region-mairie",
      "timeline.ce-devis-a-ete-approuve-par-region-mairie",
      "timeline.ce-devis-a-ete-soumis-mairie",
      "timeline.ce-devis-attente-approbation-mairie",
      "timeline.ce-devis-attente-soumission-mairie",
      "timeline.ce-devis-attente-votre-soumission-mairie",
      "timeline.detail.approuve-par-region-le-mairie",
      "timeline.soumis-region-mairie",
      "timeline.soumission-region-mairie",
      "verif-compte.devis-etabli-sur-fonds-propres-contactez-nous-mairie",
      "verif-compte.liste-etablie-sur-fonds-propres-contactez-nous-mairie",
      "verif-compte.toute-commande-sur-fonds-propres-avertissement-mairie",
    ];
  } else if (marcheType === "Departement") {
    transKeys = [
      "compte.soumission-a-region-departement",
      "devis.devis-a-approuver-help-departement",
      "devis.devis-a-commander-help-departement",
      "devis.devis-a-modifier-help-departement",
      "devis.devis-a-soumettre-help-departement",
      "devis.devis-rejete-help-departement",
      "devis.devis-soumis-a-region-departement",
      "devis.devis-soumis-a-region-help-departement",
      "devis-commande.tooltip.devis-approuve-en-attente-commande-departement",
      "devis-commande.tooltip.devis-soumis-en-attente-approbation-departement",
      "devis-commande.tooltip.devis-valide-attente-soumission-departement",
      "dotation.alloue-help-departement",
      "dotation.devis-non-soumis-help-departement",
      "dotation.devis-transmis-help-departement",
      "dotation.outil-permet-suivre-budget-alloue-departement",
      "etablissement.devis-en-attente-transmission-region-departement",
      "timeline.approbation-region-departement",
      "timeline.approuve-region-departement",
      "timeline.ce-devis-a-ete-approuve-par-region-departement",
      "timeline.ce-devis-a-ete-soumis-departement",
      "timeline.ce-devis-attente-approbation-departement",
      "timeline.ce-devis-attente-soumission-departement",
      "timeline.ce-devis-attente-votre-soumission-departement",
      "timeline.detail.approuve-par-region-le-departement",
      "timeline.soumis-region-departement",
      "timeline.soumission-region-departement",
      "verif-compte.devis-etabli-sur-fonds-propres-contactez-nous-departement",
      "verif-compte.liste-etablie-sur-fonds-propres-contactez-nous-departement",
      "verif-compte.toute-commande-sur-fonds-propres-avertissement-departement",
    ];
  } else {
    transKeys = [
      "compte.soumission-a-region",
      "devis.devis-a-approuver-help",
      "devis.devis-a-commander-help",
      "devis.devis-a-modifier-help",
      "devis.devis-a-soumettre-help",
      "devis.devis-rejete-help",
      "devis.devis-soumis-a-region",
      "devis.devis-soumis-a-region-help",
      "devis-commande.tooltip.devis-approuve-en-attente-commande",
      "devis-commande.tooltip.devis-soumis-en-attente-approbation",
      "devis-commande.tooltip.devis-valide-attente-soumission",
      "dotation.alloue-help",
      "dotation.devis-non-soumis-help",
      "dotation.devis-transmis-help",
      "dotation.outil-permet-suivre-budget-alloue",
      "etablissement.devis-en-attente-transmission-region",
      "timeline.approbation-region",
      "timeline.approuve-region",
      "timeline.ce-devis-a-ete-approuve-par-region",
      "timeline.ce-devis-a-ete-soumis",
      "timeline.ce-devis-attente-approbation",
      "timeline.ce-devis-attente-soumission",
      "timeline.ce-devis-attente-votre-soumission",
      "timeline.detail.approuve-par-region-le",
      "timeline.soumis-region",
      "timeline.soumission-region",
      "verif-compte.devis-etabli-sur-fonds-propres-contactez-nous",
      "verif-compte.liste-etablie-sur-fonds-propres-contactez-nous",
      "verif-compte.toute-commande-sur-fonds-propres-avertissement",
    ];
  }

  // Transformer les clés de traduction en camelCase et permettre la recherche par ces nouvelles clés d'objet.
  const transformedKeys = {};
  transKeys.forEach((str) => {
    let camelCaseStr = str.replace(/[-_.]+(\w)/g, (match, char) => char.toUpperCase());
    // S'il s'agit des marchés "Mairie" ou "Département", corrige la clé de recherche.
    if (marcheType === "Mairie") {
      camelCaseStr = camelCaseStr.replace("Mairie", "");
    } else if (marcheType === "Departement") {
      camelCaseStr = camelCaseStr.replace("Departement", "");
    }
    // Faire correspondre la nouvelle clé à sa clé de traduction.
    transformedKeys[camelCaseStr] = i18n.t(str);
  });

  return transformedKeys;
}
