// import Vue from "vue";
import axios from "axios";

import config from "@/config.ts";
import store from "@/store";

export default (objConfigParam) => {
  const objConfig = objConfigParam || {};

  const instance = axios.create({
    withXSRFToken: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrfnumerique",
    withCredentials: true,
    baseURL: config.api.baseUrl,
    timeout: objConfig.timeout || 600000,
  });
  instance.interceptors.response.use(
    null,
    (err) => {
      if (err.response && err.response.status && err.response.status === 403) {
        store.commit("setErrorStatus", err.response.status);
      }
      return Promise.reject(err);
    },
  );

  return instance;
};
