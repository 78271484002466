<template>
  <div
    v-if="selectIsLoading"
    class="loader flex-vcenter"
  >
    <InfiniteLoader class="loader" />
  </div>
  <div
    v-else
    class="select-organisme-input text-regular"
  >
    <SelectClassic
      v-if="displaySelect"
      v-model="formatedSelectedOrg"
      :label="label"
      :placeholder="$t('general.placeholder-preciser-chaque-session')"
      status="required"
      :options="organismesAffiches"
      :options-are-loading="optionsAreLoading"
      :internal-search="false"
      :disabled="organismesAffiches.length === 1 || disabled"
      @search="fetchOrganismes($event)"
    >
      <template #label-icon>
        <UilBuilding size="16" />
      </template>
      <template #after-option-item="{ option, }">
        <!--
          @slot Emplacement pour un élément à droite de l'option.
          @binding {Object} option Informations de l'élément du dropdown.
        -->
        <slot
          name="after-option-item"
          :option="option"
        />
      </template>
    </SelectClassic>
    <div
      v-else
      class="container-options"
    >
      <ul>
        <li
          v-for="(option, index) in organismesAffiches"
          :key="`options-list-item-${index}`"
          class="options-list-item"
          :class="{ 'active': formatedSelectedOrg.value === option.value, }"
        >
          <button
            type="button"
            class="options-list-item-btn flex-vcenter"
            :disabled="disabled"
            @click="clickOption(option)"
          >
            <span>{{ option.label }}</span>
            <div>
              <Tag
                v-if="option.type"
                :tag-name="option.type"
              />
              <UilCheck
                v-if="formatedSelectedOrg.value === option.value"
                size="16"
              />
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { SelectClassic, Tag, InfiniteLoader } from "@lde/core_lde_vue";

import { UilBuilding, UilCheck } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche l'ensemble des organismes accessibles sous forme de liste ou de dropdown selon leur nombre. N'applique pas la
 * modification à la sélection contrairement au composant SwitchOrganisme.
 */
export default {
  name: "SelectOrganismeInput",
  components: {
    SelectClassic,
    Tag,
    InfiniteLoader,
    UilBuilding,
    UilCheck,
  },
  model: {
    prop: "selectedOrg",
    event: "select",
  },
  props: {
    /**
     * Objet corresspondant à l'organisme sélectionné.
     */
    selectedOrg: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Affiche ou non l'astérisque signant l'obligation de remplir ce champ.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Texte affiché dans le select (en haut).
     */
    label: {
      type: String,
      default() { return this.$t("general.etablissement-actif-pour-session"); },
    },
    /**
     * Permet d'envoyer les options à afficher en props et non via le fetch interne au composant.
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Désactive le select.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché quand on change d'organisme.
     */
    "select",
    /**
     * Déclenché à l'affichage et lorsque les options ont fini de charger.
     */
    "select-is-loading",
  ],
  data() {
    return {
      selectIsLoading: false,
      optionsAreLoading: false,
      allOrganismes: [],
      organismes: [],
    };
  },
  computed: {
    ...mapGetters(["organismeActuel"]),
    /**
     * Formattage de l'objet du store pour correspondre au format attendu par SelectClassic
     */
    formatedSelectedOrg: {
      get() {
        if (!this.selectedOrg) {
          return {
            label: this.organismeActuel.nom_complet,
            value: this.organismeActuel.nom_complet,
            id: this.organismeActuel.id,
            type: this.organismeActuel.type,
          };
        }

        return {
          label: this.selectedOrg.label,
          value: this.selectedOrg.value,
          id: this.selectedOrg.id,
          type: this.selectedOrg.type,
        };
      },
      set(org) {
        this.$emit("select", org);
      },
    },
    organismesAffiches() {
      if (this.disabled) {
        return [
          this.formatedSelectedOrg,
        ];
      }

      return this.options.length ? this.options : this.organismes;
    },
    displaySelect() {
      return (this.options.length ? this.options : this.allOrganismes).length > 4;
    },
  },
  mounted() {
    this.selectIsLoading = true;
    this.$emit("select-is-loading", true);

    this.fetchOrganismes()
      .then(() => {
        this.selectIsLoading = false;
        this.$emit("select-is-loading", false);
      });
  },
  methods: {
    /**
     * Récupère les organismes accessibles.
     * @param {String} searchText Texte tapé dans l'input.
     */
    fetchOrganismes(searchText = "") {
      this.optionsAreLoading = true;
      return this.$store.dispatch("fetchOrganismes", searchText)
        .then((res) => {
          this.organismes = res.data.results.map(
            (org) => ({
              label: org.nom_complet,
              value: org.nom_complet,
              id: org.id,
              type: org.type,
            }),
          );
          // Permet de conserver la liste de tous les organismes pour savoir si l'utilisateur peut
          // changer d'organisme depuis le select.
          if (searchText === "") {
            this.allOrganismes = this.organismes;
          }
          this.optionsAreLoading = false;
        });
    },
    /**
     * Sélectionne une option.
     * @param {Object} option Option sélectionnée dans le dropdown.
     */
    clickOption(option) {
      if (option.id !== this.formatedSelectedOrg.id) {
        this.formatedSelectedOrg = option;
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.select-organisme-input {
  .loader { height: 100%; }

  .tag {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .container-options {
    background-color: var.$white;
    ul { list-style: none; }
  }

  .options-list-item-btn { justify-content: space-between; }
}
</style>
