var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-group-header",class:{ 'dark-mode': _vm.darkMode, }},[_c('ButtonGroup',[(_vm.hasPerm('view_liste'))?_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$tc(
          'panier.x-selection-dans-panier',
          _vm.panier?.quantite_ligne,
          { selection: _vm.panier?.quantite_ligne, }
        ),
        placement: 'bottom',
        delay: { show: 800, },
      }),expression:"{\n        content: $tc(\n          'panier.x-selection-dans-panier',\n          panier?.quantite_ligne,\n          { selection: panier?.quantite_ligne, }\n        ),\n        placement: 'bottom',\n        delay: { show: 800, },\n      }"}],attrs:{"id":"bouton_panier","variant":"solid","color":"secondary","size":"medium"},on:{"click":function($event){return _vm.$router.push({ name: 'panier', })}}},[_c('IconPanier',{attrs:{"height":"16","width":"16"}}),(_vm.isFetchingPanier)?_c('InfiniteLoader'):_vm._e(),(_vm.linesInPanier > 0 && !_vm.isFetchingPanier)?_c('Counter',{attrs:{"value":_vm.spaceNumber(_vm.linesInPanier),"size":"small"}}):_vm._e()],1):_vm._e()],1),_c('ModalListesCreation',{on:{"open-quick-list":function($event){_vm.listName = $event}}}),_c('ModalListesSaisieRapide',{attrs:{"list-name":_vm.listName}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }