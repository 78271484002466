<template>
  <aside
    v-if="user"
    id="cookies_control"
  >
    <p class="text-medium">
      {{ $t('cookie.lde-utilise-cookie') }}
    </p>
    <div
      id="buttons_container"
      class="flex-vcenter"
    >
      <ButtonClassic
        :label="$t('action.tout-refuser')"
        variant="ghost"
        icon="left"
        color="white"
        @click="rejectAll()"
      >
        <template #left-icon>
          <UilTimesCircle />
        </template>
      </ButtonClassic>
      <ButtonClassic
        :label="$t('action.tout-accepter')"
        variant="ghost"
        icon="left"
        color="white"
        @click="acceptAll()"
      >
        <template #left-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
      <ButtonClassic
        v-if="!showCustom"
        :label="$t('action.personnaliser')"
        variant="solid"
        icon="left"
        color="secondary"
        @click="showCustom = true"
      >
        <template #left-icon>
          <UilListUl />
        </template>
      </ButtonClassic>
      <ButtonClassic
        v-else
        class="validate-cookies-btn"
        :label="$t('action.valider-mes-choix')"
        variant="solid"
        icon="left"
        color="secondary"
        @click="save()"
      >
        <template #left-icon>
          <UilCheckCircle />
        </template>
      </ButtonClassic>
      <ButtonClassic
        balise-type="a"
        :label="$t('footer.rgpd')"
        icon="left"
        ext-link="config.url.rgpd"
      >
        <template #left-icon>
          <UilArrowRight />
        </template>
      </ButtonClassic>
    </div>
    <section
      v-if="showCustom"
      id="custom_cookie"
    >
      <h1 class="s5 title-separator">
        <span>{{ $t('cookie.personnalisez-vos-choix') }}</span>
      </h1>
      <p
        id="main_text_cookies"
        class="info-cookie text-regular"
      >
        {{ $t('cookie.accepter-refuser-operations') }}
        <a
          class="underline"
          :href="config.url.rgpd"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ $t('cookie.charte-pour-la-protection-des-donnees-a-caractere-personnel-rgpd') }}
        </a>.
      </p>
      <ul>
        <li class="toggle-item">
          <ButtonToggle
            :value="true"
            :label="$t('cookie.cookies-necessaires-fonctionnement-site')"
            type="switch"
            name="necessaire_site"
            disabled
          />
          <p
            class="info-cookie text-regular"
            v-html="$t('cookie.cookies-fonctionnels-necessaires')"
          />
        </li>
        <li class="toggle-item">
          <ButtonToggle
            v-model="cookies.technique"
            :label="$t('cookie.cookies-techniques')"
            type="switch"
            name="technique"
          />
          <p class="info-cookie text-regular">
            {{ $t('cookie.cookies-techniques-explication') }}
          </p>
        </li>
        <li class="toggle-item">
          <ButtonToggle
            v-model="cookies.audience"
            :label="$t('cookie.cookies-audience')"
            type="switch"
            name="audience"
          />
          <p class="info-cookie text-regular">
            {{ $t("cookie.cookies-mesure-audience-explication") }}
          </p>
        </li>
      </ul>
    </section>
  </aside>
</template>

<script>
import { ButtonClassic, ButtonToggle } from "@lde/core_lde_vue";

import Api from "@/modules/axios";
import { mapGetters } from "vuex";

import {
  UilCheckCircle,
  UilTimesCircle,
  UilListUl,
  UilArrowRight,
} from "@iconscout/vue-unicons";

import config from "@/config.ts";
/**
 * Affiche le choix des cookies.
 */
export default {
  name: "CookiesControl",
  components: {
    ButtonClassic,
    ButtonToggle,
    UilCheckCircle,
    UilTimesCircle,
    UilListUl,
    UilArrowRight,
  },
  emits: [
    /**
     * Déclenché à l'enregistrement des cookies.
     * @param {Boolean} - Visibilité du bandeau des cookies.
     */
    "save",
  ],
  data() {
    return {
      showCustom: false,
      cookies: {
        technique: false,
        audience: false,
        video: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    config() {
      return config;
    },
  },
  mounted() {
    if (this.user.cookies?.date) {
      this.cookies = this.user.cookies;
    }
  },
  methods: {
    /**
     * Sauvegarde les choix et ferme le bandeau.
     */
    save() {
      const cookies = {
        date: this.$moment().format("DD/MM/YYYY - HH:mm:ss"),
        technique: this.cookies.technique,
        audience: this.cookies.audience,
        video: this.cookies.video,
      };

      Api().patch(`/utilisateur/${this.user.id}/cookies/`, { cookies })
        .then((res) => {
          this.$store.commit("setCookies", res.data.cookies);
          this.showCustom = false;
          this.$emit("save", res.data.cookies);
        });
    },
    /**
     * Accepte tous les cookies.
     */
    acceptAll() {
      this.cookies = {
        technique: true,
        audience: true,
        video: true,
      };
      this.save();
    },
    /**
     * Refuse tous les cookies.
     */
    rejectAll() {
      this.cookies = {
        technique: false,
        audience: false,
        video: false,
      };
      this.save();
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/cookies_control.scss";
</style>
