import Api from "@/modules/axios";

export default {
  methods: {
    /**
     * Met les offres (lignes) en question dans la liste choisie et navigue vers la liste si elle est modifiée.
     * @param {Object} commit
     * @param {Object} liste Liste à laquelle ajouter les offres.
     * @param {Array} lignes Offres à ajouter dans la liste.
     * @returns {Promise}
     */
    addOffersToList({ liste, lignes = [] }) {
      return Api().post(`/liste/${liste.id}/add_lignes/`, { lignes })
        .then(({ data }) => {
          if (data.already_in_list) {
            this.$toast.success(
              { title: this.$t("liste.reference-ajoutee-deja-dans-liste-x", { liste: liste.libelle }) },
            );
          } else {
            this.$toast.success(
              { title: this.$tc("liste.reference-bien-ajoutee-liste-x", lignes.length, { liste: liste.libelle }) },
            );
          }
        })
        .catch((err) => {
          this.$toast.error({ title: err.response?.data || this.$t("info.une-erreur-est-survenue") });
        })
        .finally(() => {
          this.$store.dispatch("fetchActiveLists");
        });
    },
    /**
     * Ajoute les lignes de commande à la liste donnée et navigue vers la liste si elle est modifiée.
     * @param {Object} liste Liste à laquelle ajouter les offres.
     * @param {Array} commandes Offres à ajouter à la liste.
     * @returns {Promise}
     */
    addOrderLinesToList({ liste, commandes = [] }) {
      return Api().post(`/liste/${liste.id}/copy_order/`, { commandes })
        .then(({ data }) => {
          if (data.code === "Aucune") {
            this.$toast.error({ title: this.$t("produit.tous-les-produits-deja-dans-liste") });
          } else {
            if (data.code === "Min une") {
              this.$toast.warning({ title: this.$t("commande.toutes-offres-pas-mesure-ajoutees-liste") });
            }
            this.$toast.success({
              title: this.$tc("liste.reference-bien-ajoutee-liste-x", 2, { liste: liste.libelle }),
            });
            this.$router.push({
              name: "listes_devis_listes_item",
              params: { id: liste.id },
            });
          }
        })
        .catch((error) => {
          this.$toast.error({ title: error.response?.data || this.$t("info.une-erreur-est-survenue") });
        })
        .finally(() => {
          this.$store.dispatch("fetchActiveLists");
        });
    },
  },
};
