import Menu from "@/router/menu";

const routes = [];
Menu.forEach((parent) => {
  /** Si le parent a un nom on le push */
  if (parent.name) {
    routes.push({
      path: parent.path,
      component: parent.component,
      name: parent.name,
      alias: parent.alias,
      redirect: parent.redirect,
      beforeEnter: parent.beforeEnter,
    });
  }

  const children = parent.submenu || [];
  children.forEach((child) => {
    /** Génération des routes enfants */
    routes.push({
      path: parent.path + child.path,
      component: child.component,
      name: `${parent.name}_${child.name}`,
      alias: child.alias?.map((alias, index) => parent.alias[index] + alias),
      beforeEnter: child.beforeEnter,
      meta: {
        title: child.title,
        icon: child.icon,
        goBackBtn: child.goBackBtn,
        permissions: child.permissions,
      },
    });

    /** Génération des routes "petits-enfants" */
    const grandChildren = child.submenu || [];
    grandChildren.forEach((subchild) => {
      routes.push({
        path: parent.path + child.path + subchild.path,
        component: subchild.component,
        name: `${parent.name}_${child.name}_${subchild.name}`,
        alias: subchild.alias?.map((alias, index) => parent.alias[index] + child.alias[index] + alias),
        beforeEnter: child.beforeEnter,
        meta: {
          title: subchild.title,
          parent: `${parent.name}_${child.name}`,
          icon: subchild.icon,
          goBackBtn: subchild.goBackBtn,
          permissions: subchild.permissions,
        },
      });
    });
  });
});
export default routes;
