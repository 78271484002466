<template>
  <SearchForm
    v-model="searchTerm"
    :disabled="disabled"
    :show-suggestions="(searchSuggestions.length > 0 || inCatalog.length > 0) && !!searchTerm"
    :options="searchCategories"
    :selected-option="selectedCategory"
    :is-input-focused="isSearchInputFocused"
    :placeholder="$t('recherche.rechercher-ean-titre-etc')"
    :show-adv-search="advancedSearchActive"
    @fetch-suggestions="fetchSearchSuggestion()"
    @submit="submitSearchForm($event)"
    @input-focused="isSearchInputFocused = $event"
    @select-option="defineSelectedCategory($event)"
  >
    <template #search-suggestions>
      <SearchSuggestions
        :search-suggestions="searchSuggestions"
        :in-catalog="inCatalog"
        :text-to-highlight="searchTerm"
        @close="isSearchInputFocused = false"
        @select="submitSearchForm($event)"
        @scope-change="fetchInCatalog($event)"
      />
    </template>
  </SearchForm>
</template>

<script>
import SearchSuggestions from "@/components/search/SearchSuggestions.vue";

import IconNumerique from "@/components/icons/IconNumerique.vue";
import IconFourniture from "@/components/icons/IconFourniture.vue";
import IconPapier from "@/components/icons/IconPapier.vue";

import { UilBookOpen } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";

/**
 * Champ de recherche principal du site.
 */
export default {
  name: "MainSearchForm",
  components: {
    SearchForm,
    SearchSuggestions,
  },
  props: {
    /**
     * Désactive l'input de recherche.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchSuggestions: [],
      inCatalog: [],
      searchTerm: "",
      isSearchInputFocused: false,
      selectedCategory: null,
      showAdvSearch: false,
    };
  },
  computed: {
    ...mapGetters(["suggestionFilter", "hasPerm", "hasPerms", "advancedSearchActive"]),
    searchCategories() {
      const cat = [];
      if (this.hasPerm("view_articlepapier")) {
        cat.push({
          label: this.$t("menu.livres-et-manuels-papier"),
          icon: IconPapier,
          name: "papiers",
          route: "catalogues_livres_manuels_papier",
        });
      }
      if (this.hasPerm("view_manuelnumerique")) {
        cat.push({
          label: this.$t("menu.ressources-numeriques"),
          icon: IconNumerique,
          name: "numeriques",
          route: "catalogues_ressources_numeriques",
        });
      }
      if (this.hasPerm("view_fourniture")) {
        cat.push({
          label: this.$t("menu.fournitures-et-papeterie"),
          icon: IconFourniture,
          name: "fournitures",
          route: "catalogues_fournitures_papeterie",
        });
      }

      // Si l'utilisateur a accès à au moins deux catalogues, mettre "Tous les catlogues" en haut du menu.
      if (cat.length > 1) {
        cat.unshift({
          label: this.$t("menu.tous-les-catalogues"),
          icon: UilBookOpen,
          name: "tous",
        });
      }

      return cat;

      // {
      //   label: "Listes",
      //   icon: UilFileBookmarkAlt,
      //   name: "listes",
      //   route: "listes_devis_listes",
      // },
      // {
      //   label: "Devis",
      //   icon: IconFileDevis,
      //   name: "devis",
      //   route: "listes_devis_devis",
      // },
      // {
      //   label: "Commandes",
      //   icon: UilFileCheck,
      //   name: "commandes",
      //   route: "commandes_factures_commandes",
      // },
      // {
      //   label: "Factures",
      //   icon: IconFileDevis,
      //   name: "factures",
      //   route: "commandes_factures_factures",
      // },
      // {
      //   label: this.$t("recherche.stocks"),
      //   icon: UilBox,
      //   name: "stocks",
      // }, {
      //   label: this.$t("general.etablissement"),
      //   icon: UilBuilding,
      //   name: "etablissement",
      // },
      // {
      //   label: this.$t("general.assistance"),
      //   icon: UilQuestionCircle,
      //   name: "assistance",
      // },
    },
  },
  watch: {
    $route: {
      handler() {
        // toujours fermer la recherche avancée si on change de route.
        this.showAdvSearch = false;

        // Sélectionner la catégorie correspondant au catalogue dans lequel on se trouve.
        const categorie = this.searchCategories.find((sc) => sc.route && this.$route.name.startsWith(sc.route));
        if (categorie || !this.selectedCategory) {
          // S'il y a déjà une catégorie séléctionnée, on ne veut pas mettre la première à la place
          // Ex: sur la page "Licences" on laisse "Numériques" si l'utilisateur vient de là bas.
          // le [0] ne sert normalement qu'au mounted
          this.$set(this, "selectedCategory", categorie || this.searchCategories[0]);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Propose des suggestions selon la recherche effectuée.
     */
    fetchInCatalog() {
      // Par défaut, on prend le premier catalogue auquel l'utilisateur a accès pour les suggestions.
      let catName = this.selectedCategory.name;
      if (catName === "tous") {
        catName = this.searchCategories.find((cat) => cat.name !== catName).name;
      }
      const params = {
        search: this.searchTerm,
        scope: this.suggestionFilter,
        cat: catName,
      };
      Api().get("/recherche/custom_suggestions/", { params })
        .then(({ data }) => {
          this.inCatalog = data;
        });
    },
    /**
     * Récupération des suggestions de recherche
     */
    fetchSearchSuggestion() {
      Api().get(`/recherche/suggestions/?search=${this.searchTerm}`)
        .then(({ data }) => {
          if (data?.suggestions) {
            // Temporaire : À retirer une fois que l'on aura de vraies suggestions et non des pré-résultats.
            const searchSuggestions = data.suggestions.filter(
              (result) => result.nom_matiere !== "Résultats pertinents",
            );

            const relevantResults = data.suggestions.filter(
              (result) => result.nom_matiere === "Résultats pertinents",
            );

            // Merge des 2 tableaux pour n'en garder qu'un
            this.searchSuggestions = searchSuggestions.concat(relevantResults);
          }
          this.fetchInCatalog();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Gestion de la soumission du formulaire.
     * Si la recherche saisie dans le formulaire n'est pas déjà présente
     * dans les recherches récentes, on l'ajoute via localStorage.
     * Si la longueur du tableau dépasse 7 éléments, on retire le dernier.
     */
    submitSearchForm(searchTerms) {
      if (searchTerms) {
        this.searchTerm = searchTerms;
      }

      if (this.selectedCategory.name === "tous") {
        this.$router.push({
          name: "search",
          query: { search: this.searchTerm },
        });
      } else {
        this.$router.push({
          name: "search",
          query: { only: this.selectedCategory.name, search: this.searchTerm },
        });
      }

      // Reload la page
      this.$store.commit("addSearchKey");
      this.searchTerm = "";
    },
    /**
     * Récupère la catégorie sélectionnée.
     * @param {Object} category Infos de la catégorie.
     */
    defineSelectedCategory(category) {
      this.selectedCategory = category;
    },
  },
};
</script>
<style lang="scss"></style>
