import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import store from "@/store";

// On vérifie si on est sur la prod
let env = "devel";
if (process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT !== "devel") {
  env = process.env.VUE_APP_ENVIRONMENT === "master" ? "production" : "recette";
}

const ignoreErrors = [
  "Network Error",
  "Request aborted",
];

export default {
  init() {
    if (process.env.VUE_APP_ENVIRONMENT
      && process.env.VUE_APP_ENVIRONMENT !== "devel"
      && store.state.user?.cookies?.technique) {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
        ],
        beforeSend(event) {
          // Si l'utilisateur a modifié les cookies et a mis "technique" à false, on envoit rien
          if (!store.state.user?.cookies?.technique) {
            return null;
          }
          return event;
        },
        environment: env,
        debug: process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT !== "master",
        attachProps: true,
        attachStacktrace: true,
        autoSessionTracking: false,
        ignoreErrors,
      });
    }
  },
  close() {
    Sentry.close();
  },
  flush() {
    Sentry.flush();
  },
  unsetScope() {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
      scope.setContext("Organisme", null);
      scope.setContext("Permissions", null);
    });
  },
  setScope() {
    if (process.env.VUE_APP_ENVIRONMENT
      && process.env.VUE_APP_ENVIRONMENT !== "devel"
      && store.state.user?.cookies?.technique) {
      Sentry.configureScope((scope) => {
        if (store.state.user) {
          scope.setUser({
            id: store.state.user.id,
            email: store.state.user.email || store.state.user.contact.email,
            username: `${store.state.user.contact.nom} ${store.state.user.contact.prenom}`,
            phone: store.state.user.contact.tel,
            genre: store.state.user.contact.genre,
          });
        }
        if (store.getters.organismeActuel) {
          scope.setContext("Organisme", {
            id: store.getters.organismeActuel.id,
            id_organisme: store.getters.organismeActuel.id_organisme,
            nom_complet: store.getters.organismeActuel.nom_complet,
            nom: store.getters.organismeActuel.nom,
            commune: store.getters.organismeActuel.commune,
            cp: store.getters.organismeActuel.cp,
            pays: store.getters.organismeActuel.pays,
          });
        }
        scope.setContext("Permissions", {
          permissions: store.state.permissions,
        });
      });
    }
  },
  client: Sentry,
};
