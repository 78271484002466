<template>
  <section
    id="error_page"
    :class="variantClass"
  >
    <figure
      v-if="variant === '404' || variant === '403'"
      id="illus_img"
    >
      <img
        :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_fleche_to_top.svg')"
        :alt="$t('404.illutration-fleche-recherche')"
      />
      <UilSearchAlt size="24" />
    </figure>

    <div
      v-if="variant !== 'site-forbidden'"
      class="grid-zone"
    >
      <Toast
        :title="title || errorTitle"
        :content="content || errorContent"
        variant="error"
        show-icon
        hide-close-btn
      />
    </div>

    <div
      id="error_page_content"
      class="grid-zone"
    >
      <div id="block_left">
        <div class="text-regular">
          <h2
            v-if="variant === 'site-forbidden'"
            class="s4 bold"
          >
            {{ $t('general.bienvenue-univers-cristal') }}
            <img
              src="@lde/core_lde_vue/dist/assets/media/logos/logo_cristal.svg"
              alt="Logo Cristal"
            />
          </h2>
          <h2
            v-else
            class="s4 light"
          >
            {{ $t('general.retrouvez-eclat-cristal') }}
          </h2>
          <ul
            id="choices"
            class="text-regular"
          >
            <template v-if="variant === 'site-forbidden'">
              <li>
                <p>{{ $t("404.acces-notre-interface-pas-dispo") }}</p>
              </li>
              <li>
                <div>
                  <p v-html="$t('404.si-vous-souhaitez-effectuer-commandes-sur-fonds-propres')" />
                  <SwitchOrganisme inline />
                </div>
              </li>
            </template>
            <template v-else-if="variant === '404' && zeroSearchResults">
              <li class="flex-vcenter">
                <strong>{{ $t('404.vous-pouvez-effectuer-la-recherche-dans-tous-les-catalogues') }}</strong>
                <ButtonClassic
                  class="form-submit"
                  color="primary"
                  variant="solid"
                  icon="left"
                  :label="$t('recherche.lancer-la-recherche')"
                  @click="$emit('retry-search')"
                >
                  <template #left-icon>
                    <UilSearchAlt />
                  </template>
                </ButtonClassic>
              </li>
            </template>
            <template v-else>
              <li>
                <strong>{{ $t('404.effectuant-nouvelle-recherche') }}</strong>
              </li>
              <li class="flex-vcenter">
                <strong>{{ $t('404.en-vous-laissant-guider-par') }}</strong>
                <ButtonClassic
                  :label="$t('404.page-precedente')"
                  variant="ghost"
                  color="primary"
                  icon="left"
                  :disabled="!hasPreviousPage"
                  @click="$router.go(-1)"
                >
                  <template #left-icon>
                    <UilArrowUpLeft size="16" />
                  </template>
                </ButtonClassic>
                <ButtonClassic
                  balise-type="router-link"
                  :label="$t('404.page-accueil')"
                  variant="ghost"
                  color="primary"
                  icon="left"
                  :router-link-obj="{ name: 'home', }"
                >
                  <template #left-icon>
                    <UilEstate size="16" />
                  </template>
                </ButtonClassic>
              </li>
              <li
                v-if="isHorsMarche"
                class="flex-vcenter"
              >
                <strong>{{ $t('404.en-nous-signalant-cette-erreur') }}</strong>
                <ButtonClassic
                  v-if="!isGE"
                  v-matomo-log-click="['assistance_error_page', ]"
                  v-bind="btnAssistanceAttr"
                  :label="$t('general.assistance')"
                  color="secondary"
                  icon="left"
                >
                  <template #left-icon>
                    <UilEnvelope size="16" />
                  </template>
                </ButtonClassic>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <figure>
        <!-- @slot Possibilité de mettre une icône personnalisée. -->
        <slot
          v-if="$slots['custom-icon']"
          name="custom-icon"
        />
        <img
          v-else-if="variant === 'site-forbidden'"
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_copy_writing.svg')"
          :alt="$t('404.illustration-de-refonte-du-site')"
        />
        <img
          v-else-if="variant === '404'"
          id="illus_logo_design"
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_logo_design.svg')"
          :alt="$t('404.illustration-page-erreur')"
        />
        <img
          v-else-if="variant === '403'"
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_forbidden.svg')"
          :alt="$t('404.illustration-page-erreur')"
        />
        <img
          v-else-if="variant === 'no-results'"
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_zero_resultat.svg')"
          :alt="$t('recherche.illustration-zero-resultat')"
        />
      </figure>
    </div>
  </section>
</template>

<script>
import { ButtonClassic, Toast, copyToClipboard } from "@lde/core_lde_vue";

import SwitchOrganisme from "@/components/forms/SwitchOrganisme.vue";

import {
  UilArrowUpLeft,
  UilEstate,
  UilEnvelope,
  UilSearchAlt,
} from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche une page destinée à un contenu interdit d'accès.
 */
export default {
  name: "ErrorPage",
  components: {
    SwitchOrganisme,
    ButtonClassic,
    Toast,
    UilArrowUpLeft,
    UilEstate,
    UilEnvelope,
    UilSearchAlt,
  },
  props: {
    /**
     * Change le contenu de la page d'erreur.
     */
    variant: {
      type: String,
      validator: (value) => ["404", "403", "site-forbidden", "no-results"].includes(value),
      default: "404",
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    zeroSearchResults: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché lorsque le bouton "lancer la recherche" et cliqué.
     */
    "retry-search",
  ],
  computed: {
    ...mapGetters(["isHorsMarche", "country", "isGE", "urlHotline"]),
    isDe() {
      return this.country === "de";
    },
    hasPreviousPage() {
      return window.history.length > 1;
    },
    variantClass() {
      if (this.variant === "404") {
        return "not-found";
      }
      if (this.variant === "403") {
        return "forbidden";
      }
      return this.variant;
    },
    errorTitle() {
      if (this.variant === "404") {
        return this.$t("404.page-introuvable");
      }
      if (this.variant === "no-results") {
        return this.$t("recherche.aucun-resultat");
      }
      // Variante 403
      return this.$t("403.non-autorise");
    },
    errorContent() {
      if (this.variant === "no-results") {
        return this.$t("recherche.aucun-produit-correspondant");
      }
      if (this.variant !== "404") {
        return this.$t("403.non-autorise-content");
      }
      return "";
    },
    btnAssistanceAttr() {
      const attr = {
        baliseType: this.isDe ? "button" : "a",
      };

      if (!this.isDe) {
        attr.extLink = this.urlHotline;
      }

      return attr;
    },
  },
  methods: {
    copyToClipboard,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/layout/error_page.scss";
</style>
