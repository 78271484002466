import Api from "@/modules/axios";

export default {
  state: {
    budgetOrganisme: null,
    budgetRegion: null,
  },
  getters: {
    budgetOrganisme(state) {
      return state.budgetOrganisme;
    },
    budgetRegion(state) {
      return state.budgetRegion;
    },
    formalizeBudget: () => (budget) => {
      // Les valeurs des clées qu'on souhaite passer en Number
      const keys = [
        "commande",
        "devisATransmettre",
        "devisTransmis",
        "devisValides",
        "dotation",
        "enCours",
        "solde",
        "soldeAVenir",
      ];
      const obj = { ...budget };
      keys.forEach((element) => {
        obj[element] = parseFloat(obj[element]);
      });
      return obj;
    },
  },
  mutations: {
    setBudgetOrganisme(state, budget) {
      state.budgetOrganisme = budget;
    },
    setBudgetRegion(state, budget) {
      state.budgetRegion = budget;
    },
  },
  actions: {
    /**
     * Récupère le budget pour la région.
     */
    fetchBudgetRegion(store) {
      return new Promise((resolve, reject) => {
        Api().get("/organisme/dotations/")
          .then(({ data }) => {
            const result = data;
            const budgets = {};
            // On crée un dictionnaire de type {"id_organisme": { infos }}
            data.budgetsOrganismes.forEach((budget) => {
              const key = budget.idOrganisme.toUpperCase();
              if (key) {
                budgets[key] = budget;
              }
            });
            result.budgetsOrganismes = budgets;
            store.commit("setBudgetRegion", result);
            resolve(result);
          })
          .catch((e) => {
            if (e.response?.status === 503) {
              console.error("Le micro-service est hors-service.");
            }
            reject(e);
          });
      });
    },
    /**
     * Récupère le budget de l'organisme.
     * @param {String} idOrganisme Id de l'organisme qu'on recherche.
     */
    fetchBudgetOrganisme(store, idOrganisme) {
      return new Promise((resolve, reject) => {
        Api().get(`/organisme/${idOrganisme}/dotation/`)
          .then((res) => {
            const formalized = store.getters.formalizeBudget(res.data[0]);
            store.commit("setBudgetOrganisme", formalized);
            resolve(formalized);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
